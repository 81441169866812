import { Component, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'win-error-banner',
  standalone: true,
  imports: [MatButtonModule, TranslocoPipe],
  templateUrl: './error-banner.component.html',
  styleUrl: './error-banner.component.scss',
})
export class ErrorBannerComponent {
  error = input.required<string>();

  reloadPage(): void {
    window.location.reload();
  }
}
