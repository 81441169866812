import {
  ChangeDetectionStrategy,
  Component,
  input,
  signal,
} from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'win-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe],
})
export class FooterComponent {
  lang = input.required<string>();

  currentYear = signal<number>(new Date().getFullYear());
  imprintUrl = {
    de: 'https://menutech.com/de/impressum',
    en: 'https://menutech.com/en/imprint',
    it: 'https://menutech.com/it/informazioni-legali',
    fr: 'https://menutech.com/fr/mentions-legales',
    es: 'https://menutech.com/es/aviso-legal',
  };
  privacyUrl = {
    de: 'https://menutech.com/de/datenschutz-bestellungen-software',
    en: 'https://menutech.com/en/privacy-notice-ordertaking-app',
    it: 'https://menutech.com/it/privacy-app-gestione-delle-comande',
    fr: 'https://menutech.com/fr/confidentialite-application-prise-de-commande',
    es: 'https://menutech.com/es/privacidad-aplicacion-toma-de-ordenes',
  };
}
