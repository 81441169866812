<div
  class="language-picker"
  [class.disabled]="isOffline()"
  [matTooltip]="'app.offline.not-available' | transloco"
  [matTooltipDisabled]="isOffline() === false"
  matTooltipClass="win-tooltip"
>
  <mat-form-field
    class="field"
    data-cy="language-picker"
    subscriptSizing="dynamic"
  >
    <mat-icon matIconPrefix>language</mat-icon>
    <mat-select
      class="mat-subtitle-2 select"
      panelClass="language-panel"
      (selectionChange)="languageChange.emit($event.value)"
      [value]="currentLang()"
      [disabled]="isOffline()"
    >
      @for (lang of langs; track lang) {
        <mat-option [value]="lang">
          @if (small()) {
            <ng-container class="short">{{ lang | uppercase }}</ng-container>
          } @else {
            <ng-container class="full">{{
              'shared.languages.' + lang | transloco
            }}</ng-container>
          }
          &zwnj;
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
