<div class="mat-body footer-title">
  {{ 'app.footer.title' | transloco }}
  &copy; {{ currentYear() }}
  &middot;
  <a class="link-label" target="_blank" [href]="privacyUrl[lang()]">{{
    'app.footer.privacy' | transloco
  }}</a>
  &middot;
  <a class="link-label" target="_blank" [href]="imprintUrl[lang()]">{{
    'app.footer.imprint' | transloco
  }}</a>
</div>
