<button mat-button (click)="toggleOffline()">
  @if (isOffline() | async) {
    <span
      ><mat-icon class="is-offline" color="warn">wifi_off</mat-icon> &nbsp;<span
        [matTooltip]="'app.offline.saved-orders' | transloco"
        matTooltipClass="win-tooltip"
        ><span class="order-count">{{ orderCount() ?? 0 }}</span></span
      ></span
    >
  } @else {
    <mat-icon class="is-online">wifi</mat-icon>
  }
</button>
