import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { OfflineModeComponent } from './offline-mode/offline-mode.component';
import { AsyncPipe } from '@angular/common';
import { FontSizePickerComponent } from './font-size-picker/font-size-picker.component';
import { LanguagePickerComponent } from './language-picker/language-picker.component';
import { RouterLink } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LANGUAGES } from 'src/app/shared/constants/languages';
import { UnauthConsumer } from 'src/app/shared/models/user';

@Component({
  selector: 'win-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    FontSizePickerComponent,
    LanguagePickerComponent,
    MatToolbarModule,
    OfflineModeComponent,
    RouterLink,
  ],
})
export class HeaderComponent {
  currentLang = input.required<string>();
  isOffline = input.required<boolean>();
  logo = input.required<string>();
  offlineOrderCount = input.required<number>();
  showOfflineMode = input.required<boolean>();
  simpleConsumer = input.required<UnauthConsumer>();

  languageChange = output<keyof typeof LANGUAGES>();
}
