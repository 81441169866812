<div aria-label="Change font size" class="font-size-picker">
  <mat-form-field
    class="field"
    data-cy="font-size-picker"
    subscriptSizing="dynamic"
  >
    <mat-icon matIconPrefix>text_fields</mat-icon>
    <mat-select
      class="mat-subtitle-2 select"
      panelClass="font-size-panel"
      (selectionChange)="toggleFontSize($event)"
      [value]="currentValue"
    >
      <mat-select-trigger>&zwnj;</mat-select-trigger>
      <mat-option aria-label="Normal font size" [value]="100">100%</mat-option>
      <mat-option aria-label="Large font size" [value]="125">125%</mat-option>
      <mat-option aria-label="Huge font size" [value]="150">150%</mat-option>
    </mat-select>
  </mat-form-field>
</div>
