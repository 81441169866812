import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import { Terminal } from 'src/app/shared/models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'win-pre-footer',
  templateUrl: './pre-footer.component.html',
  styleUrls: ['./pre-footer.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    RouterLink,
    RouterLinkActive,
    TranslocoPipe,
  ],
})
export class PreFooterComponent {
  isAgent = input.required<boolean>();
  isManager = input.required<boolean>();
  isOffline = input.required<boolean>();
  isVerified = input.required<boolean>();
  terminal = input.required<Terminal | null>();
  userFullName = input.required<string>();

  exitCompletely = output<void>();

  displayName = computed(() => this.terminal()?.name ?? this.userFullName());
  isActiveAgentOrManager = computed(
    () =>
      (this.isAgent() || this.isManager()) &&
      this.isVerified() &&
      this.terminal() === null,
  );
}
