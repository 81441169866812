import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'win-font-size-picker',
  templateUrl: './font-size-picker.component.html',
  styleUrls: ['./font-size-picker.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
  ],
})
export class FontSizePickerComponent {
  private readonly document = inject(DOCUMENT);

  customFontSizeClasses = ['font-size-125', 'font-size-150'];
  currentValue = 100;

  toggleFontSize({ value }: MatSelectChange): void {
    this.document.body.classList.remove(...this.customFontSizeClasses);
    if (value === 100) return;
    this.document.body.classList.add(`font-size-${value}`);
  }
}
