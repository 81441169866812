@if (globalError$ | async) {
  <win-error-banner
    class="notification"
    [error]="globalError$ | async"
  ></win-error-banner>
}

<header class="mat-elevation-z1" role="banner">
  <win-header
    [currentLang]="globalLanguage$ | async"
    [isOffline]="isOffline$ | async"
    [logo]="logo$ | async"
    [offlineOrderCount]="offlineOrderCount$ | async"
    [simpleConsumer]="simpleConsumer$ | async"
    [showOfflineMode]="showOfflineMode$ | async"
    (languageChange)="languageChange($event)"
  >
  </win-header>
</header>

<main class="main-content" role="main">
  <router-outlet></router-outlet>
</main>

<footer class="footer" role="contentinfo">
  @if ((isUnauth$ | async) === false && (isLoggedIn$ | async) === true) {
    <win-pre-footer
      [isAgent]="isAgent$ | async"
      [isManager]="isManager$ | async"
      [isOffline]="isOffline$ | async"
      [isVerified]="emailVerified$ | async"
      [terminal]="terminal$ | async"
      [userFullName]="userFullName$ | async"
      (exitCompletely)="exitCompletely()"
    ></win-pre-footer>
  }
  <win-footer [lang]="globalLanguage$ | async"></win-footer>
</footer>
