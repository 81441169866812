<div class="wrapper">
  @if (isActiveAgentOrManager()) {
    <button
      class="footer-btn"
      routerLinkActive="active-link"
      mat-button
      routerLink="/manage/consumers"
    >
      <mat-icon>face</mat-icon>
      {{ 'manage-accesses.my-consumers' | transloco }}
    </button>
    @if (isManager()) {
      <button
        class="footer-btn"
        routerLinkActive="active-link"
        mat-button
        routerLink="/manage/accesses"
      >
        <mat-icon>group</mat-icon>
        {{ 'manage-accesses.title' | transloco }}
      </button>
    }
    <button
      class="footer-btn"
      routerLinkActive="active-link"
      mat-button
      routerLink="/manage/orders"
    >
      <mat-icon>send</mat-icon>
      {{ 'manage-orders.title' | transloco }}
    </button>
  }

  <span
    class="btn-wrapper"
    [matTooltip]="'app.offline.not-available' | transloco"
    [matTooltipDisabled]="isOffline() === false"
    matTooltipClass="win-tooltip"
  >
    <button
      class="footer-btn"
      mat-button
      routerLink="/settings"
      routerLinkActive="active-link"
      [disabled]="isOffline()"
    >
      {{ displayName() }}<mat-icon>settings</mat-icon>
    </button>
  </span>

  <span
    class="btn-wrapper logout"
    [matTooltip]="'app.offline.not-available' | transloco"
    [matTooltipDisabled]="isOffline() === false"
    matTooltipClass="win-tooltip"
  >
    <button
      mat-button
      class="footer-btn"
      (click)="exitCompletely.emit()"
      [disabled]="isOffline()"
    >
      {{ 'logout.logout-button' | transloco }}
      <mat-icon>exit_to_app</mat-icon>
    </button>
  </span>
</div>
