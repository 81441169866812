<h2 mat-dialog-title>
  @if (isOffline | async) {
    {{ 'app.offline.disable.title' | transloco }}
  } @else {
    {{ 'app.offline.enable.title' | transloco }}
  }
</h2>

<div mat-dialog-content>
  @if (isOffline | async) {
    <p>
      {{ 'app.offline.disable.description' | transloco }}
    </p>
  } @else {
    <p>{{ 'app.offline.enable.description' | transloco }}</p>
    <div class="datepicker">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>
          {{ 'app.offline.enable.select-date' | transloco }}
        </mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [min]="minDate"
          [required]="true"
          [(ngModel)]="selectedDate"
          (click)="picker.open()"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker [startAt]="initDate"></mat-datepicker>
        <mat-error>{{
          'app.offline.enable.date-required' | transloco
        }}</mat-error>
      </mat-form-field>
    </div>
  }
</div>

<div mat-dialog-actions class="actions">
  <button mat-button mat-dialog-close>
    {{ 'shared.cancel' | transloco }}
  </button>
  <button
    mat-fab
    extended
    color="primary"
    [disabled]="(isOffline | async) !== true && !selectedDate"
    [mat-dialog-close]="{ accepted: true, date: selectedDate }"
  >
    {{ 'shared.confirm' | transloco }}
  </button>
</div>
