import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { logout } from './redux/authentication/authentication.actions';
import {
  selectIsLogged,
  selectIsSimpleAuth,
} from './redux/authentication/authentication.selectors';
import { FooterComponent } from './global-components/footer/footer.component';
import { HeaderComponent } from './global-components/header/header.component';
import { PreFooterComponent } from './global-components/pre-footer/pre-footer.component';
import {
  selectGlobalError,
  selectGlobalLanguage,
} from './redux/global/global.selectors';
import {
  selectOfflineModeValue,
  selectOfflineOrderCount,
} from './redux/offline-mode/offline-mode.selectors';
import {
  selectAllowOfflineMode,
  selectEmailVerified,
  selectIsAgent,
  selectIsManager,
  selectLogo,
  selectSimpleConsumer,
  selectTerminal,
  selectUserFullName,
} from './redux/user/user.selectors';
import { filter, take, withLatestFrom } from 'rxjs';
import { fetchAvailableReports } from './redux/user/user.actions';
import { selectUrl } from './redux/router/router.selectors';
import { LANGUAGES } from './shared/constants/languages';
import { setGlobalLanguage } from './redux/global/global.actions';
import { ErrorBannerComponent } from './global-components/error-banner/error-banner.component';

@Component({
  selector: 'win-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    CommonModule,
    ErrorBannerComponent,
    FooterComponent,
    HeaderComponent,
    PreFooterComponent,
    RouterOutlet,
  ],
})
export class AppComponent implements OnInit {
  private readonly store = inject(Store);

  emailVerified$ = this.store.pipe(select(selectEmailVerified));
  globalError$ = this.store.select(selectGlobalError);
  globalLanguage$ = this.store.pipe(select(selectGlobalLanguage));
  initialUrl$ = this.store.pipe(select(selectUrl));
  isLoggedIn$ = this.store.pipe(select(selectIsLogged));
  isAgent$ = this.store.pipe(select(selectIsAgent));
  isManager$ = this.store.pipe(select(selectIsManager));
  isOffline$ = this.store.pipe(select(selectOfflineModeValue));
  isUnauth$ = this.store.pipe(select(selectIsSimpleAuth));
  logo$ = this.store.pipe(select(selectLogo));
  offlineOrderCount$ = this.store.select(selectOfflineOrderCount);
  showOfflineMode$ = this.store.select(selectAllowOfflineMode);
  simpleConsumer$ = this.store.pipe(select(selectSimpleConsumer));
  terminal$ = this.store.pipe(select(selectTerminal));
  userFullName$ = this.store.pipe(select(selectUserFullName));

  ngOnInit(): void {
    this.initialUrl$
      .pipe(
        withLatestFrom(this.isLoggedIn$),
        filter(([url, loggedIn]) => loggedIn && url !== undefined),
        take(1),
      )
      .subscribe(([url]) => {
        if (url.startsWith('/redirect') === false) {
          this.store.dispatch(fetchAvailableReports());
        }
      });
  }

  languageChange(value: keyof typeof LANGUAGES): void {
    this.store.dispatch(setGlobalLanguage({ payload: value }));
  }

  exitCompletely(): void {
    this.store.dispatch(logout());
  }
}
